import $ from 'jquery';

$(document).ready(function() {
	$(".timeline-widget").each(function() {
		//new TimeLineWidget($(this));
	});

});

function TimeLineWidget(timeLineContainer) {
	this.timeLineContainer = timeLineContainer;
	this.timelineEventsWrapper = this.timeLineContainer.find(".timeline-events");
	this.timelineEventListHolder = this.timelineEventsWrapper.find("ul");
	this.timelineContentItems = this.timeLineContainer.find(".timeline-content").find("li");

	this.currentItem = null;

	this.line = this.timeLineContainer.find(".timeline-events-line");

	var contentList = this.timeLineContainer.find(".timeline-content").find("li");
	var timeLineEventList = [];

	contentList.each(function(index, item) {
		timeLineEventList.push($(item).data("date"));
	});

	this.createTimeLine(timeLineEventList);

	this.showItem(this.timelineContentItems.first());
	this.registerEvents();
}

TimeLineWidget.prototype.createTimeLine = function(timeLineEventList) {
	var itemsPerView = 12;

	if (itemsPerView > this.timelineContentItems.length) {
		itemsPerView = this.timelineContentItems.length;
	}

	var containerWidth = this.timelineEventsWrapper.width() - 76;
	var itemWidth = parseInt(containerWidth / (itemsPerView - 1));

	for (var i = 0; i < timeLineEventList.length; i++) {
		var li = $("<li>").html(timeLineEventList[i]);
		li.attr("data-date", timeLineEventList[i]);
		li.css("left", itemWidth * i);
		this.timelineEventListHolder.append(li);
		li.data("date", timeLineEventList[i]);
	}
};

TimeLineWidget.prototype.showItem = function(item) {
	this.timelineContentItems.removeClass("selected");
	this.timelineEventListHolder.find("li").removeClass("selected").removeClass("after-selected");

	var prevItem = this.currentItem;
	this.currentItem = item;
	this.currentItem.addClass("selected");

	this.currentEventItem = this.findItemEvent(this.currentItem.data("date"));
	this.currentEventItem.addClass("selected");

	var windowWidth = $(window).width();

	var animateLine = false;

	if (prevItem !== null && prevItem.index() !== this.currentItem.index()) {
		var isAfter = item.index() > prevItem.index();

		prevItem.stop();
		item.stop();

		item.css("left", isAfter ? windowWidth : -windowWidth);

		prevItem.animate({
			left: isAfter ? -windowWidth : windowWidth
		}, 700, function() {
			prevItem.hide();
		});

		item.animate({
			"left": 0
		}, 700);

		animateLine = true;
	}

	var currentEventItem = this.findItemEvent(this.currentItem.data("date"));
	var linePosition = currentEventItem.offset().left + (currentEventItem.width() / 2);

	this.line.show();

	if (animateLine) {
		this.line.animate({
			left: linePosition
		}, 300);
	} else {
		this.line.css("left", linePosition);
	}

	currentEventItem.nextAll().each(function(index, _item) {
		$(_item).addClass("after-selected");
	});

	item.show();
};

TimeLineWidget.prototype.findItemEvent = function(date) {
	return this.timelineEventListHolder.find("[data-date='" + date + "']").first();
};

TimeLineWidget.prototype.findItem = function(date) {
	return this.timelineContentItems.filter("[data-date='" + date + "']").first();
};

TimeLineWidget.prototype.registerEvents = function() {
	var that = this;
	this.timelineEventListHolder.find("li").on("click", function(event) {
		event.preventDefault();
		that.showItem(that.findItem($(this).data("date")));
	})
};

export default TimeLineWidget;