const sections = document.querySelectorAll('.quick-start-items section');
const titles = [];

if (sections.length) {
  const selectWrapper = document.querySelector('.quick-start-select');
  const qsSelect = document.querySelector('#select-platform');

  [...sections].forEach((section, index) => {
    section.setAttribute('id', 'qs-' + (index + 1));
    section.style.display = 'none';
    const title =  section.querySelector('h1').textContent;
    titles.push(title);

    const option = document.createElement('option');
    option.setAttribute('value', index + 1);
    option.innerHTML = title;
    qsSelect.appendChild(option);
  });

  qsSelect.addEventListener('change', () => {
    showQSItem(qsSelect.value);
  });

  function showQSItem(index) {
    [...sections].forEach((section => section.style.display = 'none'));
    document.querySelector('#qs-' + index).style.display = 'block';
  }

  let index = 1;
  const defaultIndex = titles.indexOf('ia32-generic');

  if (defaultIndex >= 0) {
    index = defaultIndex + 1;
  }

  qsSelect.value = index;
  showQSItem(index);
  const wrapper = document.querySelector('#quick-start-wrapper');
  if (wrapper) {
    wrapper.style.visibility = 'visible';
  }
}

