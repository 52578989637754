import $ from 'jquery';
import spiltter from './spiltter';
import slick from 'slick-carousel';
import timeline from './timeline';
import quickStart from './quick-start';

import UAParser from 'ua-parser-js';
const uaString = window.navigator.userAgent;
const uaParser = new UAParser(uaString);
const device = uaParser.getDevice();

const isMobile = device.type === 'tablet' || device.type === 'mobile';

if (isMobile) {
  const banner = document.querySelector('.banner');
  if (banner) {
    banner.classList.add('banner-mobile');
  }

  const video = document.querySelector('.banner-video');

  if (video) {
    video.pause();
    video.parentNode.removeChild(video);
  }
}

$('#cs-slider').slick({
  dots: true,
  slidesToShow: 1,
  speed: 400,
  arrows: true,
  infinite: false
});

let num_of_slides = document.getElementById('timeline') ?
    document.getElementById('timeline').getElementsByTagName("li").length : 0;
$('#timeline').slick({
  dots: true,
  slidesToShow: 1,
  speed: 400,
  arrows: false,
  infinite: false,
  initialSlide: num_of_slides - 1,
  customPaging: function(slider, i) {
    return $(slider.$slides[i]).find('.grid').data('date');
  },
});


const mainMenu = document.querySelector('.main-menu');

const updateSelected = () => {
  const selected = $('.main-menu').find('.selected');
  const rootParent = selected.parentsUntil('.main-menu', 'li');
  rootParent.addClass('menu-open');
};

updateSelected();

mainMenu.addEventListener('click', (event) => {
  const target = event.target;

  if (target.classList.contains('menu-toggle')) {
    const parent = target.parentNode.parentNode;
    const isOpen = parent.classList.contains('menu-open');

    event.preventDefault();

    if (isOpen) {
      parent.classList.remove('menu-open');

    } else {
      parent.classList.add('menu-open');
    }
  }
});

const navbarButton = document.querySelector('.navbar-btn');
const navbar = document.querySelector('.navbar');
const body = document.querySelector('body');

if (navbarButton && navbar) {
  navbarButton.addEventListener('click', () => {
    const hasMenu = navbar.classList.contains('menu-open');

    if (hasMenu) {
      navbar.classList.remove('menu-open');
      body.classList.remove('hidden');
    } else {
      navbar.classList.add('menu-open');
      body.classList.add('hidden');
    }
  })
}

const menu = $('#menu-docs');

if (menu.length) {
  let selected = menu.find(".selected");
  let rootParent = selected.parentsUntil("#menu-docs", "li");
  rootParent.addClass("menu-open");

  selected = $("#doc-menu").find(".selected");
  rootParent = selected.parentsUntil("#doc-menu", "li");
  rootParent.addClass("menu-open");

  const fixHeight = () => {
    const footerTop = $('#footer').offset().top;
    const menuTop = menu.offset().top;
    menu.css({
      height: (footerTop - menuTop) - 4
    });
  };

  window.addEventListener('scroll', () => {
    fixHeight();
  });

  fixHeight();
  spiltter();

  const wrapper = document.querySelector('.docs-wrapper');
  if (wrapper) {
    wrapper.classList.add('ready');
  }
}

