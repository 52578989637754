export default () => {
  let splitValue = window.localStorage.getItem("split");

  const splitter = document.querySelector('.docs-splitter');
  const menu = document.querySelector('.docs-menu');
  const content = document.querySelector('.docs-content');

  if (splitter) {
    splitter.addEventListener('mousedown', onMouseDown);
  }

  if (splitValue) {
    updateSplit(splitValue);
  }

  function onMouseDown() {
    document.addEventListener('mouseup', onMouseUp);
    document.addEventListener('mousemove', onMouseMove);
  }
  function onMouseUp() {
    document.removeEventListener("mouseup", onMouseUp);
    document.removeEventListener("mousemove", onMouseMove);
    window.localStorage.setItem("split", splitValue);
  }

  function onMouseMove(event) {
    const left = document.querySelector('.docs-wrapper').offsetLeft;
    event.preventDefault();
    splitValue = event.clientX - left;

    updateSplit(splitValue);
  }

  function updateSplit(value) {
    if (value < 200) {
      value = 200;
    }
    if (value > 600) {
      value = 600;
    }
    splitter.style.left = value + 'px';
    menu.style.flex = '0 0 ' + value + 'px';
    menu.style.maxWidth = value + 'px';
  }
}


